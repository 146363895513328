@tailwind base;
@tailwind components;
@tailwind utilities;

  @import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');



html {
  scroll-behavior: smooth;
}

